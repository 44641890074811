import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Navbar.css";
import { Reorder } from "@material-ui/icons";


function Navbar() {
    const [expandNavabar, setExpandNavbar] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setExpandNavbar(false)
    }, [location]);

    return <div className="navbar" id={expandNavabar ? "open" : "close"}>
        <div className="toggleButton">
            <button onClick={() => {setExpandNavbar((prev) => !prev)}}> <Reorder/> </button>
        </div>
        <div className="links">
            <Link to="/"> Home </Link>
            <Link to="/projects"> Projects </Link>
            <Link to="/experience"> Experience </Link>
            <Link target="_blank" to="https://docs.google.com/document/d/15uTQ48Hipj_k3yG-ksZwFLum9gyEAQ5iliJuxbaf3x4/edit?usp=sharing"> Resume</Link>
        </div>
    </div>
}

export default Navbar