import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { School } from "@material-ui/icons";
import "../styles/Experience.css"

function Experience() {
    return (
        <div className="experience">
            <VerticalTimeline lineColor="#3e497a">
                <VerticalTimelineElement className="vertical-timeline-element--education" 
                                         date="2016 - 2020" 
                                         iconStyle={{background:"#3e497a", color: "fff" }}
                                         icon={<School />}
                                         > <h3 className="vertical-timeline-element-title">  Cibola High School</h3>
                                            <p> High School Diploma</p>
                </VerticalTimelineElement>

                <VerticalTimelineElement className="vertical-timeline-element--education" 
                                         date="2022 - 2023" 
                                         iconStyle={{background:"#3e497a", color: "fff" }}
                                         icon={<School />}
                                         > <h3 className="vertical-timeline-element-title">  MITxPro </h3>
                                            <p> Professional Certificate in Coding: Full Stack Development with MERN</p>
                </VerticalTimelineElement>

                
            </VerticalTimeline>
        </div>
    )

};

export default Experience