import React from "react";
import { Link } from "react-router-dom";
import { LinkedIn, GitHub, Email } from "@material-ui/icons";
import "../styles/Home.css"
import CopyToClipboardButton from "../components/CopyToClipboardButton";

import ProjectItem from "../components/ProjectItem";
import { ProjectList } from '../helpers/ProjectList';
import '../styles/Projects.css';

function Home() {
    return <div className="home">
        <div className="about">
                <h2> Hello, im Noah  </h2>
            <div className="prompt"><p>
            I am a dedicated Full Stack Software Developer with a 
            relentless commitment to expanding and enhancing my skill set.
             My primary focus in every project is to engineer swift, efficient,
              and seamless code solutions that not only meet but exceed expectations.
            </p>
             <p>
               <Link target="_blank" to="https://www.linkedin.com/in/noah-rodeghiero-b093391b9"> <LinkedIn/> </Link>
               <Link target="_blank" to="https://github.com/N0lan42"> <GitHub/>  </Link>
               <CopyToClipboardButton><Email/></CopyToClipboardButton>
             </p>
            </div>
        </div>

        <div className="projects">
        <h1>My Projects</h1>
        <div className="projectlist" >
        {ProjectList.map((project, idx) => {
            return <ProjectItem id={idx} name={project.name} image={project.image} />;
        })}
        </div>
    </div>

        <div className="skills"><h1>Skills</h1>
            <ol className="list">
                <li className="item">
                    <h2>Front-End</h2>
                    <span>  HTML, React, css, Bootstrap </span>
                </li>
                <li className="item">
                    <h2>Back-End</h2>
                    <span> MongoDB, Express, Redis   </span>
                </li>
                <li className="item">
                    <h2>languages</h2>
                    <span> JavaScript, Node, GraphQL  </span>
                </li>

            </ol>
        </div>
    </div>
}

export default Home