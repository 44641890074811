import badBank from '../assets/badBank.jpg';

export const ProjectList = [
    {
        name: "NRsBank",
        image: badBank,
        skills: "Javascrit, HTML, CSS, Express, Node, MongoDB, DockerHub",
        link: 'https://noah-rodeghierofullstackbankingapplication.vercel.app/#/',
        github: 'https://github.com/N0lan42/noahsbank',
        Desc: "FullStack Banking app designed to showcase skills with firebase, MongoDB, express and Bootstrap",
    }

];