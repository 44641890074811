import React from 'react';
import { Document, Page } from 'react-pdf';



const Resume = () => {
  
    const pdfUrl = 'https://docs.google.com/document/d/15uTQ48Hipj_k3yG-ksZwFLum9gyEAQ5iliJuxbaf3x4/edit?usp=sharing';

  return (
    
    <Document file={pdfUrl}>
      <Page pageNumber={1} />
    </Document>
  
);
}

export default Resume;
