import React from "react";
import { useParams } from "react-router-dom";
import { ProjectList } from "../helpers/ProjectList";
import { GitHub } from "@material-ui/icons";
import { Link } from "react-router-dom";
import '../styles/ProjectDisplay.css'

function ProjectDisplay() {
    const { id } = useParams();
    const project = ProjectList[id];
    return <div className="project">
        <h1> {project.name} </h1>
        <Link target="_blank" to={project.link}> <img src={project.image} /> </Link>
        <p>{project.Desc}</p>
        <p><b> Skills:</b>  {project.skills} </p>
        <Link target="_blank" to={project.github}><GitHub className="at" /></Link>
        
    </div>
}

export default ProjectDisplay