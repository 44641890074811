import React from "react";
import { LinkedIn, GitHub } from "@material-ui/icons";
import "../styles/Footer.css"
import { Link } from "react-router-dom";
function Footer () {
   return <div className="footer">
    <div className="SocialMedia">
    <Link target="_blank" to="https://www.linkedin.com/in/noah-rodeghiero-b093391b9"> <LinkedIn/> </Link>
    <Link target="_blank" to="https://github.com/N0lan42"> <GitHub/>  </Link>

    </div>
    <p> &copy; 2023 NoahRodeghiero.com </p>


    </div>
};

export default Footer