import React, { useState } from 'react';
import { Email } from '@material-ui/icons';
import '../styles/CopyToClipboardButton.css';

const CopyToClipboardButton = () => {
  const emailToCopy = 'nrodeghiero2002@email.com'; // Replace with your email address
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.value = emailToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setIsCopied(true);
  };

  return (
    <div>
        
      <button className={`copy-button ${isCopied ? 'copied' : ''}`} onClick={handleCopyToClipboard}>
        <Email/> {isCopied ? 'Copied!' : ''}
      </button>
    </div>
  );
};

export default CopyToClipboardButton;
